<docs type="md">
  hotelListRequestData: {
    request: {
      lat         : this.hotelListRequestData.event.venue.lat, //55.736183,
      lon         : this.hotelListRequestData.event.venue.lon, //37.592292,
      adults      : 1,
      children    : [],
      start_date  : this.hotelListRequestData.event.tripDateStart.format('YYYY-MM-DD'),
      end_date    : this.hotelListRequestData.event.tripDateEnd.format('YYYY-MM-DD'),
    },
    prepare: {
      event: this.hotelListRequestData.event
    }
  }
</docs>

<script>
  export default {
    name: 'hotelListLoadByCoordinateBooking',
    props: {
      p_hotelListRequestData: Object,
    },
    data() {
      return {
        hotelListRequestData: this.p_hotelListRequestData || {
          request: {
            // lat         : Float,
            // lon         : Float,
            // adults      : Int,
            // children    : Int,
            // start_date  : moment,
            // end_date    : moment,
          },
          prepare: {
            // event: null,
          }
        },
      }
    },
    methods: {
      hotelListLoad() {
        return this.$store.dispatch('hotelListBooking_load', this.hotelListRequestData);
      },
      getHotelListRequestDataByEvent(event){
        return {
          request: {
            latitude    : event.venue.lat, //55.736183,
            longitude   : event.venue.lon, //37.592292,
            radius      : 5,
            adults      : 1,
            children    : [],
            start_date  : event.tripDateStart.format('YYYY-MM-DD'),
            end_date    : event.tripDateEnd.format('YYYY-MM-DD'),

            language    : Language.getName(),
            currency    : Currency.getName(),
          },
          prepare: {
            event         : event,
            travelerCount : 1,
            total_hotel   : 100,
            nightsInDest: 2,
          }
        }
      },
    },
    computed:{
      hotelListLoadStatus(){
        if(!this.hotelListRequestData.request.longitude) {
          return 'CREATE'
        }
        return this.$store.getters.hotelListBooking_getLoadStatus(this.hotelListRequestData);
      },
      hotelListData(){
        if(!this.hotelListRequestData.request.longitude) {
          return []
        }
        return this.$store.getters.hotelListBooking_getData(this.hotelListRequestData);
      },
    }
  }
</script>
