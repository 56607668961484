import Categories from "@/class/client/Categories";
import axios from 'axios'

export default class FunTamCategories extends Categories {
    events
    venues
    #numRequestLoad = 0
    localIconPathsById = {}
    localBadIconPath
    badIcon
    badIconColor = 'red'

    async load(params = {}) {
        this.requireIcons()

        return new Promise((resolve) => {
            this.client.api.categories.get()
                .then(async response => {
                    const eventCategoryValidationFn = item => new Promise(resolve => {
                        const iconSvgPath = this.localIconPathsById.events[item.id] || this.localBadIconPath
                        if (!item.color) item.color = this.badIconColor

                        axios.get(iconSvgPath).then(response => item.iconSvgHtml = response.data)
                        resolve()
                    })
                    const venueCategoryValidationFn = item => new Promise(resolve => {
                        const iconSvgPath = this.localIconPathsById.venues[item.id] || this.localBadIconPath
                        if (!item.color) item.color = this.badIconColor

                        axios.get(iconSvgPath).then(response => item.iconSvgHtml = response.data)
                        resolve()
                    })

                    const promises = [
                        // Обработка категорий событий
                        ...response.eventCategories.map(eventCategoryValidationFn),
                        // Обработка категорий мест
                        ...response.venueCategories.map(venueCategoryValidationFn)
                    ]

                    await Promise.all(promises)
                    this.events = response.eventCategories
                    this.venues = response.venueCategories
                    this.#numRequestLoad = 0
                    resolve()
                })
                .catch(error => {
                    setTimeout(() => {
                        if (++this.#numRequestLoad < 5) return this.load()
                        throw new Error('Закончились попытки обращения к серверу')
                    }, 1000)
                    console.error(error)
                })
        })
    }

    requireIcons() {
        if (Object.keys(this.localIconPathsById).length) return;

        const icons = require.context('@client/resource/category-icons', false, /(^.*)(svg)/)
        const mappingData = require('@client/resource/category-icons/mapping.json')
        const localIconPathsById = {
            events: {},
            venues: {}
        }

        icons.keys().forEach(iconName => {
            if (iconName.slice(2, -4) === 'Звезда') this.localBadIconPath = icons(iconName)

            Object.keys(mappingData.events).forEach(id => {
                if (mappingData.events[id] === iconName.slice(2, -4)) {
                    localIconPathsById['events'][id] = icons(iconName)
                }
            })

            Object.keys(mappingData.venues).forEach(id => {
                if (mappingData.venues[id] === iconName.slice(2, -4)) {
                    localIconPathsById['venues'][id] = icons(iconName)
                }
            })
        })

        this.localIconPathsById = localIconPathsById
    }
}
