import axios from "axios";

export default (type, method, data, config, axiosInstance) => new Promise((resolve, reject) => {
    if (config) {
        config.source = axios.CancelToken.source()
        config.cancelToken = config.source.token
    }

    if (data.source) {
        data.source = axios.CancelToken.source()
        data.cancelToken = data.source.token
    }

    axiosInstance[type](method, data, config).then(response => {
        response.status >= 500 && reject('Ошибка сервера!')
        response.status >= 400 && reject('Ошибка клиента!')
        !response.data && reject('Неизвестная ошибка')
        resolve(response.data);
        Analytic.sendFirestoreData('request_api', response);
    }).catch(error => {
        axios.isCancel(error) && reject('Request canceled', error.message)
        reject(error)
    })
})