const poiPrepare = (poi) => {
  const preparedPoi = poi.map(i => {
    
    return {
      place         : i.place,
      type          : i.type,
      url           : i.url,
      description   : i.description || '',
      lat           : i.lat,
      lon           : i.lng,
    };

  });

  return preparedPoi;
};

export default poiPrepare;
