import Experience from "@/class/client/Experience";

export default class FunTamExperience extends Experience {
    isLoadedData = false
    #Promise
    // Ограничивают присвоение свойств при загрузке доп. контента
    #overriddenVariables = ['category', 'type']

    #type
    // url
    // photo_small
    prices

    constructor(exp, client) {
        super(client);
        delete this.type
        this.id = exp.id
        this.categoryId = exp.category
        this.coordinates = exp.coordinates
        this.#type = exp.type
        this.address = exp.address
        this.description = exp.description
        this.descShort = exp.description
        this.image = exp.image
        this.provider = exp.provider
        this.title = exp.title
        this.rating = exp.rating
        this.setPrice(exp.priceStr)
        if (this.emojis[this.categoryId]?.length)
            this.emoji = this.emojis[this.categoryId][Math.floor(Math.random() * this.emojis[this.categoryId].length)]

        if (this.isType('event')) this.category = this.client.categories.events.find(item => item.id === this.categoryId)
        else this.category = this.client.categories.venues.find(item => item.id === this.categoryId)
        if (!this.category) this.category = this._errorCategory
    }

    loadEventData() {
        return this.client.api.events.getById(this.id)
            .then(response => {
                for (const [key, value] of Object.entries(response))
                    !this.#overriddenVariables.some(item => item === key) && (this[key] = value)
                this.afterLoadData()
            })
            .catch(error => console.debug(error))
    }

    loadVenueData() {
        return this.client.api.venues.getById(this.id)
            .then(response => {
                for (const [key, value] of Object.entries(response))
                    !this.#overriddenVariables.some(item => item === key) && (this[key] = value)
                this.afterLoadData()
            })
            .catch(error => {
                console.debug(error)
            })
    }

    afterLoadData() {
        if (this.link && this.link.indexOf('dayVisited') === -1)
            this.link += (this.link.indexOf('?') > 0 ? '&' : '?') + 'dayVisited=' + (new Date).setHours(0, 0, 0, 0)
    }

    setPrice(price) {
        let numbers = price.replace(/\D/g, '')

        this.priceStr = price
        this.price = numbers ? parseInt(numbers) : 0
    }

    isType(type) {
        if (type === 'venue') type = 'area'
        return this.type === type;
    }

    loadData() {
        if (!this.isLoadedData && !this.#Promise) {
            if (this.type === 'event') this.#Promise = this.loadEventData()
            else this.#Promise = this.loadVenueData()

            this.#Promise.then(() => this.isLoadedData = true)
        }
        return this.#Promise
    }

    get type() {
        return  this.#type === 'venue' ? 'area' : this.#type
    }
}