
export const API_DOMAIN = CONST.url.apiServerBase

export const AXIOS_CONFIG = {
    baseURL: API_DOMAIN + '/api/3.0/',
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        'Accept-Language': Language.getName(),
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJmdW50YW0gYmFja2VuZCIsImlhdCI6MTY3NjY0NzQxM30.N9yzsvc8g56aHj_vfa8xdWBdskjIJl6atbgI1puMTl0'
    }
}
