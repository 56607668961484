import BaseClass from "./BaseClass";

export default class ExperiencesRequest extends BaseClass{
    /**
     * @param data
     * @param config
     * @return {never}
     */
    get(data, config) { return this.methodError() }

    /**
     * @param id
     * @param options
     * @param options.type [undefined|event|area]
     * @return {Promise}
     */
    getById(id, options) { return this.methodError() }
}