import Api from "@/class/client/Api";
import categoriesApi from "./api/categories";
import eventsApi from "./api/events";
import venuesApi from "./api/venues";
import pulseApi from "./api/pulse";

export default class FunTamApi extends Api {
    categories = categoriesApi
    events = eventsApi
    venues = venuesApi
    pulse = pulseApi
}