// import calculateDistance from "@/function/hotel/calculateDistance";
import hotelPrepareItem from "./hotelPrepareItem";

/*
* partnerHotelsPrepare
*/

// TODO: fix event -> use supplement
// {
//   travelerCount: 1,
//   total_hotel  : 100,
//   nightsInDest : 1,
//   event: event,
// }
export default (hotels, event) => {
  const preparedHotels = hotels.map(i => {
    const hotel = {
      id              : 'partner__' + i.lat + '_' + i.lng,
      provider        : 'partner',
      name            : i.name,
      stars           : 0,
      rating          : 0,
      rating_verbose  : "Superb",
      hotel_url       : i.url,
      latitude        : i.lat,
      longitude       : i.lng,
      images          : [
        {
          url: "#",
        }
      ],
      kind            : "Hotel",
      rooms: [
        {
          name: '',
          price: {USD: 72}, // TODO: fix
          USD: 72,// TODO: fix
          breakfast_included: false,
          rooms_available: 1,
          refundable: false
        }
      ],

      currency       : i.currency,
      price: i.price,  // TODO учитывать количество дней и количество человек
    };

    // TODO: fix
    const prepared = hotelPrepareItem(hotel,{
      travelerCount: 1,
      total_hotel  : 100,
      nightsInDest : 1,
      event: event,
    });
    return prepared;
  });

  return preparedHotels;
};
