import ExperiencesRequest from "@/class/client/ExperiencesRequest";

export default class FunTamExperiencesRequest extends ExperiencesRequest {
    async get(requestParams, config) {
        return this.client.api.pulse.get(requestParams, config)
    }

    async getById(id, options) {
        const type = options.type

        return type === 'event' ? this.client.api.events.getById(id) : this.client.api.venues.getById(id)
    }
}