/**
 * example:
  hotelFilter(hotel_list, { stars: 3, minRating: 8, budget: { value: 220, currency: "USD" }, })
 */

export default (hotelList, params) => {
  const filterSuite = {
    stars: (hotel) => {
      if (params.stars.length === 0) {
        return true;
      }
      return params.stars.includes(+hotel.stars);
    },
    minRating: (hotel) => {
      if (params.minRating === 0) {
        return hotel;
      }

      const rating = hotel.rating ? hotel.rating : 0;
      // return params.minRating <= rating && rating < params.minRating + 1;
      return params.minRating <= rating && rating < 10;
    },
    budget: (hotel) => {
      const price = hotel.roomWithMinimalPrice.price[params.budget.currency];
      const budgetMin = params.budget.value[0];
      const budgetMax = params.budget.value[1];

      return budgetMin <= price && price <= budgetMax;
    },

    cancelation: (hotel) => {
      if (!params.cancelation) {
        return true;
      }

      return hotel.roomWithMinimalPrice.refundable === true;
    }
  };

  if (params.stars && params.stars.length > 0) {
    params.stars.push(0);
  }
  // В список фильтров попадают только те функции, по которым приходят параметры
  const filters = Object.keys(params).map(i => filterSuite[i]);

  // Функция для проверки отеля по списку фильтров
  const check = (hotel) => filters.reduce((acc, f) => (acc ? f(hotel) : acc), true);

  return hotelList.filter(h => check(h));
};
