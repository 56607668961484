import Client from "@/class/client/Client";
import FunTamCategories from "./FunTamCategories";
import FunTamApi from "./FunTamApi";
import FunTamExperiencesRequest from "./FunTamExperiencesRequest";
import FunTamExperiences from "./FunTamExperiences";

/**
 * @typedef {?FunTamClient} Client
 */

export default class FunTamClient extends Client {
    /** @return FunTamCategories */
    get categories() { return this._categories = this._categories || new FunTamCategories(this) }
    /** @return FunTamApi */
    get api() { return this._api = this._api || new FunTamApi() }
    get experiencesRequest() { return this._experiencesRequest = this._experiencesRequest || new FunTamExperiencesRequest(this) }
    get experiences() { return this._experiences = this._experiences || new FunTamExperiences(this) }
}