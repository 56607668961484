/**
 * @dateStart, @dateEnd type: moment
 */

const getNightCount = (dateStart, dateEnd) => {

  let _a = [dateEnd.year(), dateEnd.month(), dateEnd.date()];
  let _b = [dateStart.year(), dateStart.month(), dateStart.date()];
  let a = moment(_a);
  let b = moment(_b);

  let nightsInDest = a.diff(b, 'days');
  nightsInDest = nightsInDest || 1;

  return nightsInDest;
};

export default getNightCount;
