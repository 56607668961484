import axios from 'axios'
import {AXIOS_CONFIG} from "./config";
import request from "@/api/requestMethod";

const instance = axios.create(AXIOS_CONFIG)

export default {
    instance,
    request,
    get: (method, params) => request('get', method, { params }, undefined, instance),
    post: (method, data, config) => request('post', method, data, config, instance),
}
